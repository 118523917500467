import React, { useEffect, useState } from "react";
import "../css/Dashboard.css";
import defaultDiscordPhoto from "../images/defaultDiscordPhoto.png";
const settings = {
	//apiURL: "http://localhost:4912",
	apiURL: "https://api.birb.ovh",
};

const Dashboard = () => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [selectedGuild, setSelectedGuild] = useState(null);
	const [guildSettings, setGuildSettings] = useState(null);
	const [guildChannels, setGuildChannels] = useState([]);
	const [guildUsers, setGuildUsers] = useState([]);
	const [currentTickets, setCurrentTickets] = useState([]);
	const [ticketTypes, setTicketTypes] = useState([]);
	const [reactRoleDetails, setReactRoleDetails] = useState([]);
	const [guildRules, setGuildRules] = useState([]);
	const [currentPage, setCurrentPage] = useState("home");

	useEffect(() => {
		fetch(`${settings.apiURL}/api/v1/dashboard`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		})
			.then((response) => response.json())
			.then((data) => {
				console.log("Fetched data:", data);
				setData(data);
				setLoading(false);
			})
			.catch((error) => {
				console.error("Error:", error);
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		if (data && selectedGuild) {
			const settings = data.botGuilds
				.flatMap((botGuild) => botGuild.settings || [])
				.find((setting) => setting.guildId === selectedGuild.guildId);

			const channels = data.botGuilds
				.flatMap((botGuild) => botGuild.channels || [])
				.filter((channel) => channel.guildId === selectedGuild.guildId);

			const users = data.botGuilds
				.flatMap((botGuild) => botGuild.users || [])
				.filter((user) => user.guildId === selectedGuild.guildId);

			const tickets = data.botGuilds
				.flatMap((botGuild) => botGuild.tickets || [])
				.filter((ticket) => ticket.guildId === selectedGuild.guildId);

			const filteredTicketTypes = data.botGuilds
				.flatMap((botGuild) => botGuild.ticketTypes || [])
				.filter((type) => type.guildId === selectedGuild.guildId);

			const roles = data.botGuilds
				.flatMap((botGuild) => botGuild.reactRoleDetails || [])
				.filter((role) => role.guildId === selectedGuild.guildId);

			// Filter rules based on the selected guildId
			const rules = data.botGuilds
				.flatMap((botGuild) => botGuild.rules || [])
				.filter((rule) => rule.guildId === selectedGuild.guildId);

			setGuildSettings(settings || {});
			setGuildChannels(channels || []);
			setGuildUsers(users || []);
			setCurrentTickets(tickets || []);
			setTicketTypes(filteredTicketTypes || []);
			setReactRoleDetails(roles || []);
			setGuildRules(rules || []); // Add this line to update guild rules
		}
	}, [selectedGuild, data]);

	if (loading) {
		return <div>Loading...</div>;
	}

	if (!data) {
		return <div>Error loading data.</div>;
	}

	const totalGuilds = data.botGuilds
		? data.botGuilds.reduce((acc, botGuild) => acc + botGuild.guilds.length, 0)
		: 0;

	const totalTickets = data.botGuilds
		? data.botGuilds.reduce((acc, botGuild) => acc + botGuild.tickets.length, 0)
		: 0;

	const totalUsers = data.botGuilds
		? data.botGuilds.reduce((acc, botGuild) => acc + botGuild.users.length, 0)
		: 0;

	const handleGuildClick = (guild) => {
		setSelectedGuild(guild);
	};

	const handleBackToGuilds = () => {
		setSelectedGuild(null);
		setGuildSettings(null);
		setGuildChannels([]);
		setGuildUsers([]);
		setCurrentTickets([]);
		setTicketTypes([]);
		setReactRoleDetails([]);
		setGuildRules([]);
	};

	const deployCommands = () => {
		// Get the guild ID from the selectedGuild object
		const guildId = selectedGuild.guildId;

		// Send a POST request to the backend to deploy commands

		fetch(`${settings.apiURL}/api/v1/bot/deploy/${guildId}`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
			body: JSON.stringify({ guildId }),
		})
			.then((response) => response.json())
			.then((data) => {
				alert(data.message);
			})
			.catch((error) => {
				alert(data.message);
			});
	};

	const deployCommandsGlobal = () => {
		// Double check if the user wants to deploy commands to all guilds
		const confirmDeploy = window.confirm(
			"Are you sure you want to deploy commands to ALL guilds?\nThis action cannot be undone! Deploy commands in batches to make sure they work as expected."
		);

		if (confirmDeploy) {
			// Send a POST request to the backend to deploy commands globally
			fetch(`${settings.apiURL}/api/v1/bot/deployAll`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				credentials: "include",
			})
				.then((response) => response.json())
				.then((data) => {
					alert(data.message);
				})
				.catch((error) => {
					alert(data.message);
				});
		}
	};

	return (
		<div className="dashboard">
			<div className="navbar">
				<ul>
					<li onClick={() => setCurrentPage("home")}>Home</li>{" "}
					{/* Update onClick to set currentPage to "home" */}
					<li onClick={() => setCurrentPage("guilds")}>Guilds</li>{" "}
					{/* Update onClick to set currentPage to "home" */}
					<li onClick={() => setCurrentPage("commands")}>Commands</li>{" "}
					{/* Add onClick to set currentPage to "about" */}
					<li onClick={() => setCurrentPage("contact")}>Contact</li>{" "}
					{/* Add onClick to set currentPage to "contact" */}
				</ul>
			</div>
			<div className="header">
				<div className="header__left">
					<div className="user_info">
						<img
							src={
								data.userData.avatar
									? `https://cdn.discordapp.com/avatars/${data.userData.id}/${data.userData.avatar}.png`
									: defaultDiscordPhoto
							}
							alt="User avatar"
						/>
						<h3>Welcome, {data.userData.username}</h3>
					</div>
				</div>
				<h1>Birb-bot Dashboard</h1>
				<h2>Total guilds: {totalGuilds}</h2>
				<h2>Total tickets: {totalTickets}</h2>
				<h2>Total users: {totalUsers}</h2>
				<button onClick={deployCommandsGlobal}>Deploy Commands GLOBALY</button>
			</div>

			<div className="body">
				{selectedGuild ? (
					<div className="guild-details">
						<h2>{selectedGuild.guildName} Details</h2>
						<div className="guild-buttons">
							<button onClick={handleBackToGuilds}>Back to Guilds</button>
							<button onClick={deployCommands}>Deploy Commands</button>
						</div>
						<div className="guild-settings">
							<h3>Settings</h3>
							{guildSettings ? (
								<div className="settings-grid">
									{Object.entries(guildSettings).map(([key, value]) => (
										<div key={key} className="settings-item">
											<strong>{key.replace(/([A-Z])/g, " $1")}: </strong>
											{key === "welcomeUserImage" && value ? (
												<img
													src={value}
													alt="Welcome User"
													className="welcome-user-image"
												/>
											) : (
												<span>{value !== null ? value.toString() : "N/A"}</span>
											)}
										</div>
									))}
								</div>
							) : (
								<p>No settings available for this guild.</p>
							)}
						</div>
						<div className="guild-ticketTypes">
							<h3>Ticket Types</h3>
							{ticketTypes.length > 0 ? (
								<div className="ticketTypes-grid">
									{ticketTypes.map((type, index) => (
										<div key={index} className="ticketType-item">
											<h4>
												{type.emoji} {type.typeName}
											</h4>
											<p>{type.description}</p>
											<p>
												<strong>Embed Title:</strong> {type.embedTitle}
											</p>
											<p>
												<strong>Embed Description:</strong>{" "}
												{type.embedDescription}
											</p>
											<p>
												<strong>Embed Color:</strong>{" "}
												<span style={{ color: type.embedColor }}>
													{type.embedColor}
												</span>
											</p>
										</div>
									))}
								</div>
							) : (
								<p>No ticket types available for this guild.</p>
							)}
						</div>
						<div className="react-role-details">
							<h3>React Role Details</h3>
							{reactRoleDetails.length > 0 ? (
								<div className="react-role-details-grid">
									{reactRoleDetails.map((role) => (
										<div key={role.roleId} className="react-role-item">
											<h4>
												{role.emoji} {role.roleName}
											</h4>
											<p>Role ID: {role.roleId}</p>
										</div>
									))}
								</div>
							) : (
								<p>No react role details available for this guild.</p>
							)}
						</div>
						<div className="guild-rules">
							<h3>Server Rules</h3>
							<div className="rules-grid">
								{guildRules.map((rule) => (
									<div key={rule.ruleNumber} className="rule-item">
										<h4>Rule {rule.ruleNumber}</h4>
										<p>{rule.ruleContent}</p>
									</div>
								))}
							</div>
						</div>

						<div className="guild-users">
							<h3>Users</h3>
							{guildUsers.length > 0 ? (
								<div className="users-grid">
									{guildUsers.map((user) => (
										<div key={user.userId} className="user-item">
											<img
												src={user.avatarURL || defaultDiscordPhoto}
												alt={`${user.username}'s avatar`}
												className="user-avatar"
											/>
											<h4>{user.username}</h4>
											<p>ID: {user.userId}</p>
											<p>
												Roles:{" "}
												{JSON.parse(user.roles)
													.map((role) => role.name)
													.join(", ")}
											</p>
										</div>
									))}
								</div>
							) : (
								<p>No users available for this guild.</p>
							)}
						</div>
						<div className="current-tickets">
							<h3>Current Tickets</h3>
							{currentTickets.length > 0 ? (
								<div className="tickets-grid">
									{currentTickets.map((ticket) => (
										<div key={ticket.id} className="ticket-item">
											<h4>Ticket #{ticket.ticketnumber}</h4>
											<p>Type: {ticket.ticketType}</p>
											<p>Created By: {ticket.createdBy}</p>
											<p>Status: {ticket.ticketClosed ? "Closed" : "Open"}</p>
											<p>
												Created At:{" "}
												{new Date(ticket.created_at).toLocaleString()}
											</p>
										</div>
									))}
								</div>
							) : (
								<p>No tickets available for this guild.</p>
							)}
						</div>
						<div className="guild-channels">
							<h3>Channels</h3>
							{guildChannels.length > 0 ? (
								<div className="channels-grid">
									{guildChannels.map((channel) => (
										<div key={channel.id} className="channel-item">
											<h4>{channel.name}</h4>
											<p>ID: {channel.id}</p>
										</div>
									))}
								</div>
							) : (
								<p>No channels available for this guild.</p>
							)}
						</div>
					</div>
				) : null}
				{currentPage === "home" && (
					<div>
						<h2>Home</h2>
					</div>
				)}
				{currentPage === "guilds" && (
					<div>
						<h2>Guilds</h2>
						<div className="guilds-list">
							{data.botGuilds.map((botGuild) =>
								botGuild.guilds.map((guild) => (
									<div
										key={guild.guildId}
										className="guild-item"
										onClick={() => handleGuildClick(guild)}
									>
										<img
											src={guild.iconURL || defaultDiscordPhoto}
											alt={guild.guildName}
											className="guild-icon"
										/>
										<h3>{guild.guildName}</h3>
									</div>
								))
							)}
						</div>
					</div>
				)}

				{currentPage === "commands" && (
					<div>
						<h2>Command stats</h2>
						{data.botCommandStats
							.map((stat, index) => (
								<div
									key={index}
									className={`command-stat ${
										stat.success === 1
											? "command-stat-success"
											: "command-stat-fail"
									}`}
								>
									<p>Command: {stat.command}</p>
									<p>Timestamp: {new Date(stat.timestamp).toLocaleString()}</p>
									<p>User ID: {stat.userId}</p>
									<p>Success: {stat.success === 1 ? "Yes" : "No"}</p>
									<p>Guild ID: {stat.guildId}</p>

									{stat.success === 0 && <p>Error: {stat.error}</p>}
								</div>
							))
							.reverse()}
					</div>
				)}
				{currentPage !== "home" &&
					currentPage !== "guilds" &&
					currentPage !== "commands" &&
					currentPage !== "contact" &&
					null}
			</div>
		</div>
	);
};

export default Dashboard;
