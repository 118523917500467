import React, { useState } from "react";
import "../css/App.css";

const App = () => {
	const [redirecting, setRedirecting] = useState(false);

	const handleBotAdd = () => {
		setRedirecting(true);
		window.location.href =
			"https://discord.com/oauth2/authorize?client_id=1203562745598058496&scope=bot&permissions=67431504";
	};

	return (
		<div className="Main">
			<div className="Header">
				<h1>Birb-bot "Is it finished yet?"</h1>
			</div>
			<div className="Content-head">
				<p>Birb-bot 🐧</p>
			</div>
			<div className="Content-row">
				<div className="Content-row-colum-border">
					Birb-bot is being built in node.js with discord.js. The main purpose
					of the bot is to take paid functions of other bots and turn them into
					free to use systems . The bot is currently in development and is being
					worked on by me (.imbirb) you can invite it to your server by clicking
					the that button right here ---->
				</div>
				<div className="buttons">
					<button onClick={handleBotAdd}>
						{redirecting ? "Redirecting..." : "Invite Birb-bot"}
					</button>
				</div>
			</div>
		</div>
	);
};

export default App;
