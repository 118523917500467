import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import App from "./js/App";
import Dashboard from "./js/Dashboard";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<Router>
			<Routes>
				<Route path="/" element={<App />} />
				<Route path="/api/bot/Dashboard" element={<Dashboard />} />
			</Routes>
		</Router>
	</React.StrictMode>
);

reportWebVitals();
